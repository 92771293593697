import { Match, Switch, createResource, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import { Portal, render } from 'solid-js/web';
import styles from './index.module.css';
import { Register } from './register.tsx';
import { SignIn } from './signin.tsx';

function launchConditionalAuthentication() {
    let { signal, abort } = new AbortController();

    let options = {
        rpId: window.location.hostname,
        allowCredentials: [],
        challenge: new ArrayBuffer(),
    };

    let promise = navigator.credentials.get({
        publicKey: options,
        mediation: 'conditional',
        signal: signal,
    });

    return [promise, abort];
}

async function authenticate() {
    let publicKey = {
        rpId: window.location.hostname,
        allowCredentials: [],
        challenge: new ArrayBuffer(),
    };

    let mediation = "required";

    console.log("authentication request", publicKey);

    try {
        let credential = await navigator.credentials.get({ publicKey, mediation });
        console.log("authenticate with", credential);
    } catch (e) {
        console.error(e);
    }
}

async function register(identity) {
    let publicKey = {
        rp: {
            id: window.location.hostname,
            name: "foo",
        },
        user: {
            id: (new TextEncoder()).encode("test"),
            name: identity,
            displayName: "",
        },
        pubKeyCredParams: [
            { alg: -7, type: "public-key" },
            { alg: -257, type: "public-key" },
        ],
        authenticatorSelection: {
            authenticatorAttachment: "platform",
            requireResidentKey: true,
        },
        challenge: new ArrayBuffer(),
    };
    console.log("registration request", publicKey);
    let credential = await navigator.credentials.create({ publicKey });
    console.log(credential);
}

function Index() {
    let [state, update] = createStore({
        identity: '',
        register: true,
    });

    const signin = async () => {
        try {
            console.log("attempt signin");
            let c = await authenticate()
            console.log("signin credential", c);
        } catch (e) {
            console.error("signin error", e);
        }
    }

    const signup = (identity) => {
        (async () => {
            try {
                console.log("attempt signup", identity);
                let c = await register(identity)
                console.log("signup credential", c);
            } catch (e) {
                console.error("signup error", e);
            }
        })();
    }

    const dismiss = () => update("register", register => !register);

    return (
        <div className={styles.index}>
            <h1>Welcome to Application!</h1>

            <Switch>
                <Match when={!state.register}>
                    <SignIn   submit={signin} dismiss={dismiss}/>
                </Match>
                <Match when={state.register}>
                    <Register submit={signup} dismiss={dismiss}/>
                </Match>
            </Switch>
        </div>
    );
}

render(() => <Index />, document.body);
