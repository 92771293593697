import { Component } from 'solid-js';

export const SignIn: Component = (props) => {
    const dismiss = (event: Event) => {
        event.preventDefault();
        props.dismiss();
    }

    return (
        <div>
            <h1>Sign in to your account.</h1>

            <div>
                <button onclick={props.submit}>Sign In</button>
                <a href="" onclick={dismiss}>Create account</a>
            </div>
        </div>
    );
}
