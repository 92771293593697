import { Component } from 'solid-js';
import { createStore } from 'solid-js/store';
import styles from './register.module.css';

export const Register: Component = (props) => {
    // FIXME: identity '' when done testing
    let [store, update] = createStore({ identity: 'foo@bar.com' });

    const submit = async (event: Event) => {
        event.preventDefault();
        let response = await fetch('/api/foo');
        console.log(response);
        props.submit(store.identity);
    }

    const dismiss = (event: Event) => {
        event.preventDefault();
        props.dismiss();
    }

    return (
        <div className={styles.register}>
            <form onSubmit={submit}>
                <h1>Create a new account.</h1>

                <label for="identity">Email Address</label>

                <input
                    type="email"
                    id="identity"
                    name="identity"
                    value={store.identity}
                    onInput={(e) => update('identity', e.target.value)}
                    placeholder="hello@example.com"
                    autocomplete="email"
                    autofocus required
                />

                <div>
                    <input type="submit" value="Create">Create</input>
                    <a href="" onClick={dismiss}>Sign In</a>
                </div>
            </form>
        </div>
    )
};
